import { useState } from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "~/component/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/component/ui/sheet";
import { Menu } from "lucide-react";
import LogoVerticalIcon from "~/component/icon/LogoVerticalIcon";
import {buttonVariants} from "~/component/ui/button";
import {cn} from "~/component/ui/lib/utils";

interface RouteProps {
  href: string;
  label: string;
  menuName: string;
}

const routeList: RouteProps[] = [
  { href: "/app/customer", label: "Client", menuName: "/app/customer" },
  { href: "/app/place", label: "Salle", menuName: "/app/place" },
  { href: "/app/coach", label: "Coach", menuName: "/app/coach" },

  { href: "/install", label: "Commencer", menuName: "/install" },
  { href: "/contact-us", label: "Contactez-nous", menuName: "/contact-us" },
];

const itemSelectedClassName = "text-secondary underline"
const linkItem = "hover:text-secondary text-black text-semibold"

export interface NavbarProps { menuItemTo: string, mode: "normal" | "primary" | "secondary", }

export const Navbar = ({ menuItemTo, mode = "normal" }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <header className={cn("sticky border-b-[1px] top-0 z-40 w-full dark:border-b-slate-700 dark:bg-background", {
      "bg-white": mode === "normal",
      "bg-primary-background text-white border-primary-background": mode === "primary",
      "bg-secondary-background text-black border-secondary-background": mode === "secondary",
    })}>
      <NavigationMenu className="mx-auto">
        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between">
          <NavigationMenuItem className="font-bold flex">
            <a
              rel="noreferrer noopener"
              href="/"
              className="ml-2 font-bold text-xl flex"
            >
              <LogoVerticalIcon
                color={mode === "primary" ? "white" : undefined}
              />
            </a>
          </NavigationMenuItem>

          {/* mobile */}
          <span className="flex md:hidden">
            <Sheet
              open={isOpen}
              onOpenChange={setIsOpen}
            >
              <SheetTrigger className="px-2">
                <Menu
                  className="flex md:hidden h-5 w-5"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Menu Icon</span>
                </Menu>
              </SheetTrigger>

              <SheetContent side={"bottom"}>
                <SheetHeader>
                  <SheetTitle className="font-bold text-xl">
                    <LogoVerticalIcon />
                  </SheetTitle>
                </SheetHeader>
                <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                  {routeList.map(({ href, label, menuName }: RouteProps) => (
                    <a
                      rel="noreferrer noopener"
                      key={label}
                      href={href}
                      onClick={() => setIsOpen(false)}
                      className={cn(buttonVariants({ variant: "link" }), linkItem, {
                        [itemSelectedClassName]: menuItemTo === menuName,
                      })}
                    >
                      {label}
                    </a>
                  ))}
                </nav>
              </SheetContent>
            </Sheet>
          </span>

          {/* desktop */}
          <nav className="hidden md:flex gap-2">
            {routeList.map((route: RouteProps, i) => (
              <a
                rel="noreferrer noopener"
                href={route.href}
                key={i}
                className={cn(buttonVariants({ variant: "link" }), linkItem, {
                  [itemSelectedClassName]: menuItemTo === route.menuName,
                }, "text-[17px]")}
              >
                {route.label}
              </a>
            ))}
          </nav>

        </NavigationMenuList>
      </NavigationMenu>
    </header>
  );
};