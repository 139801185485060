import useRootData from "~/hook/useRootData";

type Props = {
  path: string;
  title: string;
  description: string;
  type?: "website" | "article";
  error?: any;
  matches: any;
}

export function useMeta({
  path,
  title,
  description,
  type = "website",
  error,
  matches,
}: Props) {
  const { DOMAIN_URL } = useRootData();
  let finalTitle = `${title} - Jayne`

  if (error) {
    finalTitle = `${title ?? "Error"} - Jayne`
  }

  return [
    {title: finalTitle },
    {name: "description", content: description },
    {property: "og:url", content: `${DOMAIN_URL}/${path}`},
    {property: "twitter:url", content: `${DOMAIN_URL}/${path}`},
    {property: "og:type", content: type},
    {property: "og:title", content: finalTitle},
    {property: "twitter:title", content: finalTitle},
    {property: "og:description", content: description},
    {property: "twitter:description", content: description},
  ];
}